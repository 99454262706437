import AddressDTO from "@/dto/AddressDTO";

export default class Fill8822Request {
    public documentId: number | null = null;

    public isDeclarationsExist: boolean | null = null;

    public isEmployeePlanReturn: boolean | null = null;

    public isBusinessLocation: boolean | null = null;

    public businessLocation: AddressDTO = new AddressDTO();

    public businessName: string | null = null;

    public ein: string | null = null;

    public oldMailingAddress: AddressDTO = new AddressDTO();

    public newMailingAddress: AddressDTO = new AddressDTO();

    public newBusinessLocation: AddressDTO = new AddressDTO();

    public phone: string | null = null;

    public role: string | null = null;
}