































































































































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import Fill8822Request from "@/dto/Fill8822Request";
import {STATE_LIST} from "@/constants/ProfileConstants";
import PhoneInput from "@/components/util/PhoneInput.vue";
import DocumentService from "@/services/DocumentService";
import RouteNames from "@/router/RouteNames";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import Workspaces from "@/state/Workspaces";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
    components: {PhoneInput}
})
export default class RequestF8822Modal extends Vue {
    private message = "";
    private successful = false;
    private states = STATE_LIST;
    private routes = RouteNames

    @Auth.Getter("hasAdministrativePrivileges")
    private hasAdministrativePrivileges!: boolean;

    private request = new Fill8822Request();

    @AppModule.Action
    private startLoading!: () => void

    @AppModule.Action
    private stopLoading!: () => void

    public mounted(): void {
        if (!this.hasAdministrativePrivileges) {
            this.$router.push(this.routes.HOME);
        }
        this.request.documentId = Number.parseInt(this.$route.params.documentId, 10);
    }

    public maskHandle() {
        let mask = "__-_______";
        let oldVal = this.request.ein!.replace(/\D/g, "");

        if (mask.length >= this.request.ein!.length) {
            this.request.ein = this.buildPhoneWithMask(mask, oldVal);
        } else if (mask.length < this.request.ein!.length){
            this.request.ein = this.request.ein!.substr(0, mask.length)
        }
    }

    private buildPhoneWithMask(mask: string, oldVal: string) {
        let i = 0;

        let newValue = this.deleteSuffixIfNeed(mask.replace(/[_\d]/g, function (a:string) {
            return i < oldVal.length ? oldVal.charAt(i++) : a
        }));

        if (newValue.length <= mask.length) {
            oldVal = newValue;
        }

        if (this.request.ein!.substr(oldVal.length -1) == "-") {
            newValue = oldVal.substr(0, oldVal.length);
        }

        return newValue;
    }

    private deleteSuffixIfNeed(newValue: string) {
        if  (newValue.indexOf("_") != -1) {
            newValue = newValue.substr(0, newValue.indexOf("_"))
        }

        if (newValue.substr(newValue.length - 1) == "-") {
            newValue = newValue.substr(0, newValue.length - 1);
        }

        let suffix = newValue.substr(newValue.length - 2);

        if (suffix == " (" || suffix == ") ") {
            newValue = newValue.substr(0, newValue.length - 2)
        }

        return newValue;
    }

    zipCodeHandler() {
        if (this.request.oldMailingAddress.zipCode.length > 5) {
            this.request.oldMailingAddress.zipCode = this.request.oldMailingAddress.zipCode.substr(0, 5);
        }
        if (this.request.newMailingAddress.zipCode.length > 5) {
            this.request.newMailingAddress.zipCode = this.request.newMailingAddress.zipCode.substr(0, 5);
        }
        if (this.request.businessLocation.zipCode.length > 5) {
            this.request.businessLocation.zipCode = this.request.businessLocation.zipCode.substr(0, 5);
        }
    }

    createForm() {
        this.$validator.validateAll().then(
            isValid => {
                if (isValid) {
                    this.startLoading()
                    DocumentService.fillF8822B(this.request).then(
                        () => {
                            this.successful = true;
                            this.stopLoading();
                            this.$router.push({name: this.routes.ARCHIVE_F8822, params: {type: "c", id: `${Workspaces.getCurrent.id}`}});
                        },
                        err => {
                            this.successful = false;
                            this.stopLoading();
                            this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
                        }
                    )
                }
            }
        )
    }

}
